import * as THREE from 'three';

export const lights = (scene) => {
    const dirLight = new THREE.DirectionalLight( 0xffffee,30 );
    dirLight.position.set( -1,0.90, 3 );
    dirLight.position.multiplyScalar( 70);
    dirLight.name = "dirlight";

    scene.add( dirLight );
    
    dirLight.castShadow = true;
    
    const d = 300;
    
    dirLight.shadowCameraLeft = -d;
    dirLight.shadowCameraRight = d;
    dirLight.shadowCameraTop = d;
    dirLight.shadowCameraBottom = -d;
    
    dirLight.shadowCameraFar = 3500;
    dirLight.shadowBias = -0.0001;
    dirLight.shadowDarkness = 0.35;

   

    const hemiLight = new THREE.HemisphereLight( 0xddeeff, 0x0f0e0d, 0.02 );
    hemiLight.intensity = 50
    scene.add( hemiLight );




}