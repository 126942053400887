


import React, { useState } from 'react';

import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';


import { FaDoorOpen } from "react-icons/fa";
import { RxDimensions } from "react-icons/rx";
import { RiWindowsLine } from "react-icons/ri";
import { GiElectricalSocket } from "react-icons/gi";
import { GiBrickWall } from "react-icons/gi";
import { PiCookingPot } from "react-icons/pi";
import { FaShower } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";

import Select from "react-select";



import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { ReactComponent as Koto} from './koto.svg';
import { Chip } from '@mui/material';


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const Form = ({setFocusValue, updateFormData}) => {
    const [formData, setFormData] = useState({
        width: '',
        height:'',
        type: 'CLT-280-C7s',
        door1X: '', //
        door1Y: '',
        door1Width: '',
        door1Height: '',

        door2X: '', //
        door2Y: '',
        door2Width: '',
        door2Height: '',

        door3X: '', //
        door3Y: '',
        door3Width: '',
        door3Height: '',


        window3X: '', //
        window3Y: '', //
        window3width: '', //
        window3height: '', //
 
        window2X: '', //
        window2Y: '', //
        window2width: '', //
        window2height: '', //

        window1X: '', //
        window1Y: '', //
        window1width: '', //
        window1height: '', //

        socket1: false,
        socket1X: '',
        socket1Y:'',


        socket2: false,
        socket2X: '',
        socket2Y:'',


        socket3: false,
        socket3X: '',
        socket3Y:'',


        deur1: false,        deur2: false,        deur3: false,
        raam1: false, raam2: false, raam3: false,

        frigo: true,
        cooker: true,
        sink: true,

        cladding: {
            displayName: "Cladding - Default",
            materialName: "4",
            availableFor: "CLADDING"
          },

          pannen:    {
            displayName: "Pannen - Zwart",
            materialName: "63",
            availableFor: "PANNEN"
          },
    });


    const [activeStep, setActiveStep] = React.useState(0);

    const [url,setUrl] = useState('');
    const [status,setStatus] = useState('INITIAL'); // INITIAL , LOADING , DONE

    const claddings = [
        {name: 'Battens - Standaard', CODE: '4', color: '0xFFFFFF'},
        {name: 'Battens - Tortilla', CODE: '4', color: '0x997950'},
        {name: 'MDF', CODE: '3', color: '0xFFFFFF' },
        {name: 'Pannen', CODE: '63', color: '0xFFFFFF'},
    ]

    const walltypes = [
        {name: 'CLT-280-C7s', CODE:'CLT-280-C7s' , LIBRARY: "CLT-WALLS_BNL"},
        {name: 'EXT.CC-300', CODE:'EXT.CC-300' , LIBRARY: "V3_WALLARCH_BNL"},
    ];

    const doortypes = [
        {name: 'Glas 6mm', CODE:'CLT-280-C7s' , LIBRARY: "CLT-WALLS_BNL"},
        {name: 'Houten EIK', CODE:'EXT.CC-300' , LIBRARY: "V3_WALLARCH_BNL"},
        {name: 'Houten BEUK', CODE:'EXT.CC-300' , LIBRARY: "V3_WALLARCH_BNL"},
    ];



    const handleSubmit = async (e) => {
        setStatus('LOADING');
        e.preventDefault();

        
        

        const requestBody = {
            name: "CC" + Math.floor(Math.random()*999999), 
            model : "Chalet_01",
            parameters: [
                {
                    name: "chalet_width",
                    value: formData.width
                },
                {
                    name: "chalet_height",
                    value: formData.height
                }
            ],
objects: [

    /*
    {object:"wall_type",name: "wall_type", parameters: {"TEST":500}, DX:0,DY:0, DZ:0, CODE: walltypes.find(x => x.name == formData.type).CODE, LIBRARY: walltypes.find(x => x.name == formData.type).LIBRARY},    
    {object:"wall",name: "set_wall", parameters: {"TEST":500}, DX:0,DY:0, DZ:0, "NEWPARAMS":`-|-||IN|0|${formData.wallHeight}`},
    {object:"door_01", name: ( formData.deur1? "set_door_01" : "remove_door_01"), parameters: {ROW: formData.door1Width,ROH:formData.door1Height}, DX:formData.door1X,DY:0, DZ:formData.door1Y},
    {object:"door_02", name: ( formData.deur2? "set_door_02" : "remove_door_02"), parameters: {ROW: formData.door2Width,ROH:formData.door2Height}, DX:formData.door2X,DY:0, DZ:formData.door2Y},
    {object:"door_03", name: ( formData.deur3? "set_door_03" : "remove_door_03"), parameters: {ROW: formData.door3Width,ROH:formData.door3Height}, DX:formData.door3X,DY:0, DZ:formData.door3Y},


    {object:"window_01", name: ( formData.raam1? "set_window_01" : "remove_window_01"), parameters: {ROW: formData.window1width,ROH:formData.window1height}, DX:formData.window1X,DY:0, DZ:formData.window1Y},
    {object:"window_02", name: ( formData.raam2? "set_window_02" : "remove_window_02"), parameters: {ROW: formData.window2width,ROH:formData.window2height}, DX:formData.window2X,DY:0, DZ:formData.window2Y},
    {object:"window_03", name: ( formData.raam3? "set_window_03" : "remove_window_03"), parameters: {ROW: formData.window3width,ROH:formData.window3height}, DX:formData.window3X,DY:0, DZ:formData.window3Y},


    {object:"stopcontact_01","name": ( formData.socket1?  "set_stopcontact_01" : "remove_stopcontact_01"), "parameters": {"TEST":500}, DX:formData.socket1X,DY:0, DZ:formData.socket1Y},
    {object:"stopcontact_02","name":  ( formData.socket2? "set_stopcontact_02" : "remove_stopcontact_02" ), "parameters": {"TEST":500},  DX:formData.socket2X,DY:0, DZ:formData.socket2Y},
    {object:"stopcontact_03","name":  ( formData.socket3? "set_stopcontact_03" : "remove_stopcontact_03"), "parameters": {"TEST":500}, DX:formData.socket3X,DY:0, DZ:formData.socket3Y}
    */
]


        };

 
        console.log(requestBody);
        //return;


        try {
            const response = await fetch('https://us-central1-poc-wandshop.cloudfunctions.net/wandshop', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                const url = await data.url;
                console.log(url);
                setUrl(url);
                setStatus('DONE')
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    const options = [
        {
            displayName: "Cladding - Default",
            materialName: "4",
            availableFor: "CLADDING"
          },{
              displayName: "OSB",
              materialName: "3",
              availableFor: "CLADDING"
            },
            {
              displayName: "Pannen - Zwart",
              materialName: "63",
              availableFor: "CLADDING"
            },
            {
                  displayName: "OSB",
                  materialName: "3",
                  availableFor: "PANNEN"
                },
                {
                  displayName: "Pannen - Zwart",
                  materialName: "63",
                  availableFor: "PANNEN"
                },

          
      ];

    const updateMaterial = (value,name) => {
        setFormData({ ...formData, [name]: value });
        console.log(formData);
    }

    const handleChange = (e) => {
        console.log(e.target.value)
        console.log(JSON.stringify(e.target.value));
        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log(formData);
    };

    const handleCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        console.log(formData);

    };

    updateFormData(formData);




    const steps = [
        {
          label: 'Klanten informatie',
          sublabel: 'Alle informatie voor het opmaken van een offerte',
          content: <>
          
          <div className="section">
                    <h2><span><IoPersonOutline />&nbsp;Naam klant</span></h2>

                    <div className="form-group">
                        <input type="text" id="width" name="width" value={formData.width} onChange={handleChange} required />
                    </div>
                    
                </div>


                <div className="section">
                    <h2><span><IoMailOutline />&nbsp;Email klant</span></h2>

                    <div className="form-group">
                        <input type="text" id="width" name="width" value={formData.width} onChange={handleChange} required />
                    </div>
                    
                </div>


                </>
         
        },
        {
          label: 'Configuratie afmetingen',
          sublabel: 'Afmetingen die de basis vormen van het ontwerp',
          content: <>
          
          <div className="section" onClick={() => setFocusValue('chalet')} onMouseLeave={()=> setFocusValue('')}
>
                    <h2><span><RxDimensions />&nbsp;Afmetingen chalet</span></h2>

                    <div className='group'>
                    <div className="form-group">
                        <label htmlFor="width">Breedte (mm):</label>
                        <input type="number" id="width" name="width" value={formData.width} onChange={handleChange} required />
                    </div>
                    
                      
                        <div className="form-group">
                            <label htmlFor="height">Hoogte (mm):</label>
                            <input type="number" id="height" name="height" value={formData.height} onChange={handleChange} required />
                        </div>
                     { /*
                    <div className="form-group">
                        <label htmlFor="type">Type:</label>
                        <select id="type" name="type" value={formData.type} onChange={handleChange} required>
                            {walltypes.map(wt => (<option value={wt.CODE}>{wt.name}</option>))}
                            
                        </select>
                    </div> 
                    */
                }
                    </div>
                </div>




                <div className="section" onClick={() => setFocusValue('gevel')} onMouseLeave={()=> setFocusValue('')}
>
                    <h2><span><GiBrickWall />&nbsp;Gevelbekleding</span></h2>
     <div className='group'>


     <div className="form-group">


                        <Select
                            name="cladding"
                            options={options.filter(o => o .availableFor == 'CLADDING')}
                            value={formData.cladding}
                            onChange={(e) => updateMaterial(e,"cladding")}
                            getOptionLabel={(option) => option.displayName}
                            getOptionValue={(option) => option}
                        />

                    </div> 


                        </div>

                    </div> 


                    <div className="section" onClick={() => setFocusValue('pannen')} onMouseLeave={()=> setFocusValue('')}
>
                    <h2><span><GiBrickWall />&nbsp;Pannen</span></h2>
     <div className='group'>


     <div className="form-group">


                        <Select
                            name="pannen"
                            options={options.filter(o => o .availableFor == 'PANNEN')}
                            value={formData.pannen}
                            onChange={(e) => updateMaterial(e,"pannen")}
                            getOptionLabel={(option) => option.displayName}
                            getOptionValue={(option) => option}
                        />

                    </div> 


                        </div>

                    </div> 

          </>
         
        },
        {
          label: 'Configuratie inrichting',
          sublabel: 'Inrichting volgens de wensen van de klant',
          content: <>
          
          <div className="section" onClick={() => setFocusValue('door')} onMouseLeave={()=> setFocusValue('')}
          >
                              <h2><span><FaDoorOpen />&nbsp;Binnendeuren</span></h2>
               <div className='group'>
          
          
               <div className="form-group">
                                  <select id="type" name="type" value={formData.type} onChange={handleChange} required>
                                      {doortypes.map(wt => (<option value={wt.CODE}>{wt.name}</option>))}
                                      
                                  </select>
                              </div> 
          
                                  </div>
          
                              </div> 
          
          
          
                              <div className="section" onClick={() => setFocusValue('keuken')} onMouseLeave={()=> setFocusValue('')}
          >
                              <h2><span><PiCookingPot />&nbsp;Keuken</span></h2>
               <div className='group'>
          
          
               <div className="form-group">
               <div className='switch'>
                              <span>
                              Fornuis
                              </span>
                              <Switch defaultChecked {...label} onChange={handleCheckbox} name='cooker' value={formData.cooker}  />
          
                  </div>
          
                  <div className='switch'>
                              <span>
                              Wasbak
                              </span>
                              <Switch defaultChecked {...label} onChange={handleCheckbox} name='sink' value={formData.sink}  />
          
                  </div>
          
                  <div className='switch'>
                              <span>
                              Frigo
                              </span>
                              <Switch defaultChecked {...label} onChange={handleCheckbox} name='frigo' value={formData.frigo}  />
          
                  </div>
               
                              </div> 
          
          
                                  </div>
          
                              </div> 
          
          
          
                              <div className="section" onClick={() => setFocusValue('badkamer')} onMouseLeave={()=> setFocusValue('')}
          >
                              <h2><span><FaShower />&nbsp;Badkamer</span></h2>
          
          
                              </div> 
                    </>
        },
      ];
      
      
        const handleNext = () => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };
      
        const handleBack = () => {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };
      
        const handleReset = () => {
          setActiveStep(0);
        };
      



    return (

          
<>

<h1 className='titleform'><Koto className='logoKoto' />Koto viewer - POC </h1>


<form onSubmit={handleSubmit}>

    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={step.sublabel}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>
                {step.content}







              </Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button size="small"
variant="contained" disableElevation                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                 
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Uw aanvraag is ingediend!</Typography>
          <Button size='small' variant='contained' disableElevation onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  






















{/*
                <button disabled={!(status=='INITIAL')} type="submit"> {status == 'LOADING' ? (<div className='buttonloader'><CircularProgress />LOADING...</div>) : 'Ontwerp chalet'} </button><br /><br />

                {url != '' ? <a href={url} >Download project</a> : 'Fill in the form and submit the form... '}


           
*/
}
</form>           
        </>

    );
};