/**
 * !! Revere engineers from Vertex - This is not ready for production and needs to be checked with Vertex to give permission to use it.
 */

import * as THREE from "three";
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';


let materialToMeshMap = {};

// Array of ThreeJS meshes to raycast againts
let metaObjectsMesh = [];
let serialToEntity = {};
let modelHasExtensionData = false;
let nextId = 0;
let metadataLoaded = false;

let kinematicMeshes = [];
let doorlessMeshes = [];
let doorlessMergedMesh = null;

let metaSerialToMesh = new Map();
let metaSerialToMetaObject = new Map();

let modelTreeSystem;
let explosionSystem;
let kinematicsSystem;

let objectArray;
let zLevels;
let projectType;
let scene;

export const init_exention = (scenea, extensionData) => {

    scene = scenea;

            //modelTreeSystem = scene.systems['model-tree'];
            //explosionSystem = scene.systems['explode-model'];
            //kinematicsSystem = scene.systems['kinematics'];

            objectArray = extensionData['objects'] || [];
            zLevels = extensionData['zlevels'];
            projectType = extensionData['project_type'];

            // Go through the meshes and group them by the material name for the mesh creation
            scene.traverse( ( object ) => {
                if ( object.isMesh ){
                    var name = object.name;
                    materialToMeshMap[ name ] = object;
                }
            });

            // Find out if model has searchable meta data
            for (var i = 0; i < objectArray.length; i++ ){
                if (objectArray[i].hasOwnProperty('mesh_info')) {
                    modelHasExtensionData = true;
                    break;
                }
            }

            // Show CAD loading information if it's present
            if (modelHasExtensionData) {
                console.log("Loading CAD data...")
            } else {
                // warnings.setNoExtensionDataWarning();
                console.log("No exenstion data found!")
            }

            // Calculate the normals after the meshes have been loaded
            scene.updateMatrixWorld(true);
            scene.traverse((object) => {
                if (object.isMesh) {
                    object.geometry.computeVertexNormals();
                    object.matrixAutoUpdate = false;
                    // The original matrix is copied and stored so that it can be used in gradual loading without it
                    // changing mid-loading. Without this, the initial orientation of the metaObjects of animated
                    // objects is non-deterministic!
                    object.userData.originalMatrix = object.matrixWorld.clone();
                }
            });




                    // Start loading objects
                    metaObjectLoad();
                    console.log(metaObjectsMesh);
                    console.log(materialToMeshMap);
                    console.log(serialToEntity);
                    console.log(metaSerialToMesh);
                    console.log(metaSerialToMetaObject);


return({metaSerialToMesh,metaObjectsMesh, materialToMeshMap,metaSerialToMetaObject} );
};


        

        /**
         * Loads countOfMetaObjectsToLoadPerTick amount of meta objects per frame tick
         */
       export const metaObjectLoad = () => {
            for ( var i = 0; i < objectArray.length; i++ ){
                processExtensionObject( objectArray[i] );
            }


            metadataLoaded = true;
        }

       export const sceneHasAnimation = () => {
            return (explosionSystem && explosionSystem.hasAnimation()) ||
                (kinematicsSystem && kinematicsSystem.hasAnimation());
        }

        /**
         * Called per frame, currently updates meta objects position if animations
         * and loads meta data if not yet fully loaded
         */
        const tick = () => {

            var tmpPositionVector = new THREE.Vector3();
            var tmpQuat = new THREE.Quaternion();
            // Update meta data transformation if animation present
            if (sceneHasAnimation()) {
                for (var i = 0; i < metaObjectsMesh.length; ++i) {
                    var graphicsMesh = metaObjectsMesh[i].userData.graphicsMesh;
                    graphicsMesh.getWorldPosition(tmpPositionVector);
                    metaObjectsMesh[i].position.copy(tmpPositionVector);
                    graphicsMesh.getWorldQuaternion(tmpQuat);
                    metaObjectsMesh[i].setRotationFromQuaternion(tmpQuat);
                    metaObjectsMesh[i].updateMatrixWorld();
                }
            }

            // Per frame coroutine to load meta data
            //if (objectArray && !metadataLoaded) {
            //    gradualMetaObjectLoad();
            //}

        }
        /**
         * @return returns metaobject hold by this system
         */
        const getMetaObjectsMesh = () => {
            return metaObjectsMesh;
        }

        /**
         * @return returns project type (bd, ind, g4) or null
         */
        const getProjectType = () => {
            return projectType;
        }

        /**
         * Returns a mesh that is otherwise merged but doesn't contain doors.
         * @return returns the doorless merged mesh.
         */
        const getDoorlessMesh = () => {
            buildAccelerationStructures();
            return doorlessMergedMesh;
        }

        /**
         * Returns all kinematic objects in the scene that are used as handles for interaction.
         * @return an array of all kinematic handle objects.
         */
        const getKinematicHandleObjects =() => {
            return kinematicMeshes;
        }

        const getMaterialMeshMap = () => {
            return materialToMeshMap;
        }

        /**
         * @return returns data about vertical levels (kind of like floors but not really).
         */
        const getZLevels = () => {
            return zLevels ? zLevels : null;
        }

        /**
         * Builds acceleration structures for faster collision testing (mostly needed in VR).
         */
        const buildAccelerationStructures = () => {
            if (doorlessMergedMesh === null) {
                getMetaObjectsMesh().forEach(
                    mesh => {
                        mesh.geometry.computeBoundingSphere();
                        mesh.geometry.computeBoundsTree();
                    }
                );
                // Build full BVH from the final merged geometries
                if (doorlessMeshes.length === 0) {
                    // Probably missing metadata, so let's just take everything from the scene to get workable
                    // collisions.
                    scene.object3D.traverse( function( object ){
                        if ( 'geometry' in object ) {
                            let geom = object.geometry.clone();
                            geom.computeVertexNormals();
                            geom.attributes = {position: geom.attributes['position'], normal: geom.attributes['normal']};

                            var mesh = new THREE.Mesh( geom );
                            mesh.applyMatrix4(object.userData.originalMatrix);
                            doorlessMeshes.push(mesh);
                        }
                    });
                }

                if ( doorlessMeshes.length > 0 && doorlessMergedMesh === null ) {
                    let geometries = [];
                    // Hack: apply transform before merging, undo after.
                    doorlessMeshes.forEach(mesh => {
                        mesh.geometry.applyMatrix4(mesh.userData.originalMatrix);
                        geometries.push(mesh.geometry);
                    });
                    let mergedGeom = BufferGeometryUtils.mergeBufferGeometries(geometries, false);
                    doorlessMeshes.forEach(mesh => mesh.geometry.applyMatrix4(mesh.userData.originalMatrix.clone().invert()));
                    mergedGeom.computeBoundsTree();
                    doorlessMergedMesh = new THREE.Mesh(mergedGeom);
                }
            }
        }

        /**
         * Initializes data needed in VR.
         */
        const onEnterVR = () => {
            buildAccelerationStructures();
        }

        /**
         * Recursive function that searches for meta objects that contain mesh data
         * Used to find highlightable entities
         * @param metaObject meta object from which to find childrens recursively to bottom
         * @param listOfChildrenSerials a list OUT of found child entities
         */
        const findChildrenSerialsForMetaObject = (metaObject, listOfChildrenSerials) => {
            for ( var i = 0; i < metaObject.children.length; i++ ) {
                var childSerial = metaObject.children[i];
                var child = metaSerialToMetaObject.get(childSerial);
                if (child === undefined) {
                    continue;
                }

                if ( !child.hasOwnProperty('mesh_info') ) {
                    findChildrenSerialsForMetaObject( child, listOfChildrenSerials );
                }
                else {
                    listOfChildrenSerials.push(childSerial);
                }
            }
        }

        /**
         * Tries to find or create a meta object with given meta serial
         * @param metaObjectSerial meta object serial, defined in the meta object
         * @param callback, callback when the created entity is loaded or when entity is found
         */
        const createOrFindEntityForMetaObjectSerial = (metaObjectSerial, callback) => {
            metaObjectSerial = Number.parseInt( metaObjectSerial );
            if (metaObjectSerial in serialToEntity) {
                var entity = serialToEntity[metaObjectSerial];
                if (callback != null) {
                    callback(entity);
                }
                if (sceneHasAnimation()) {
                    updateEntityBoundingBox(entity)
                }
                return entity;
            }

            var metaObject = metaSerialToMetaObject.get(metaObjectSerial);
            if (metaObject === undefined) {
                return null;
            }

            // Try find a single meta mesh related to the serial
            var mesh = metaSerialToMesh.get(metaObjectSerial);
            // If present -> the serial is tied to single mesh
            // If NOT present -> the serial is tied to multiple meshes
            if (mesh !== undefined) {
                return createEntityForMetaObjectMesh(mesh, callback);
            }
            else {
                var childrenArray = [];
                // Meta object combined from multiple meshes
                findChildrenSerialsForMetaObject(metaObject,childrenArray);
                return createEntityForMetaObjectSerialArray(metaObject, childrenArray, callback);
            }
        }

        /**
         * Updates bounding box of given a-frame entity, used if the scene has animation,
         * to update the targeting bounding box
         * @param entity a-frame entity
         */
        const updateEntityBoundingBox = (entity) => {
            var metaMesh;
            if (entity.object3D.userData.childEntities) {
                var childEntities = entity.object3D.userData.childEntities;
                for (var childEntity of childEntities) {
                    metaMesh = childEntity.object3D.userData.metaMesh;
                    if (metaMesh !== undefined) {
                        childEntity.object3D.userData.boundingBox.setFromObject(metaMesh);
                    }
                }
                entity.object3D.userData.boundingBox = createBoundingBoxFromEntities(childEntities);
            }
            else {
                metaMesh = entity.object3D.userData.metaMesh;
                entity.object3D.userData.boundingBox.setFromObject(metaMesh);
            }
        }

        /**
         * Create's ThreeJS BoundingBox from array of A-Frame entities
         * @param  entities array of a-frame entities
         * @return returns the created bounding box
         */
        const createBoundingBoxFromEntities = (entities) => {
            var boundingBox = new THREE.Box3();
            for (var entity of entities) {
                boundingBox.union(entity.object3D.userData.boundingBox);
            }
            return boundingBox;
        }

        /**
         * Create's A-Frame entity from the meta object, removes the meta object from the list
         * @param  mesh threejs object
         * @param callback, callback to call when entity is loaded
         * @return returns the created entity
         */
        const createEntityForMetaObjectSerialArray = (metaObject, childSerialArray, callback) => {
            let childOutlineEntities = [];
            for (let childSerial of childSerialArray) {
                let childEntity = createOrFindEntityForMetaObjectSerial(childSerial, null);
                childOutlineEntities.push(childEntity);
            }

            let newElement = document.createElement('a-entity');
            newElement.setAttribute('highlightable',{showContextMenuOnRightClick: false});
            newElement.setAttribute('multi-outline', '');
            newElement.setAttribute('hideable', '');
            if ( metaObject['animation_id'] !== undefined ) {
                newElement.setAttribute('kinematic',{id:  metaObject['animation_id']});
            }
            newElement.setAttribute('metadata', {
                metadataObject: JSON.stringify( metaObject )
            });

            newElement.object3D.userData.boundingBox = createBoundingBoxFromEntities(childOutlineEntities);
            newElement.object3D.userData.childEntities = childOutlineEntities;

            scene.appendChild( newElement );

            let id = metaObject.serial;
            serialToEntity[id] = newElement;
            addLoadedEvent(newElement, callback);

            return newElement;
        }


        /**
         * Create's A-Frame entity from the meta object, removes the meta object from the list
         * @param  mesh threejs object
         * @param callback, callback to call when entity is loaded
         * @return returns the created entity
         */
        const createEntityForMetaObjectMesh = (mesh, callback) => {
            let newElement = document.createElement('a-entity');
            newElement.setAttribute('highlightable',{showContextMenuOnRightClick: true});
            newElement.setAttribute('hideable', '');
            if ( mesh.userData.metaObject['animation_id'] !== undefined ) {
                newElement.setAttribute('kinematic',{id: mesh.userData.metaObject.animation_id});
            }
            newElement.setAttribute('outline','');
            newElement.setAttribute('placeable-surface', '');
            console.log(mesh.userData.graphicsMesh);
            newElement.object3D.userData.graphicsMesh = mesh.userData.graphicsMesh;
            newElement.object3D.userData.metaMesh = mesh;

            newElement.object3D.userData.boundingBox = new THREE.Box3();
            newElement.object3D.userData.boundingBox.setFromObject(mesh);
            newElement.setAttribute('metadata', {
                metadataObject: JSON.stringify( mesh.userData.metaObject )
            });

            scene.appendChild( newElement );

            let id = mesh.userData.metaObject.serial;
            serialToEntity[id] = newElement;

            addLoadedEvent(newElement, callback);
            return newElement;
        }

        /**
         * Creates or finds an A-Frame entity for every single meta object
         * @param callback, callback to call when all entities have been loaded
         * @return returns an array of all entities
         */
      export  const createOrFindEntityForAllMetaObjectMeshes = (callback) => {
            let count = metaObjectsMesh.length;
            let entities = [];
            for (let metaMesh of metaObjectsMesh) {
                let id = metaMesh.userData.metaObject.serial;
                if (id in serialToEntity) {
                    entities.push(serialToEntity[id]);
                    count--;
                    if (count === 0 && callback) {
                        callback(entities);
                    }
                } else {
                    createEntityForMetaObjectMesh(metaMesh, function (entity) {
                        entities.push(entity);
                        count--;
                        if (count === 0 && callback) {
                            callback(entities);
                        }
                    });
                }
            }
        }

        const addLoadedEvent = (element, callback) => {
            element.addEventListener('loaded', function () {
                if (callback !== null) {
                    callback(element);
                }
            });
        }

        /**
         * Emit an event from the meta object, called from custom-cursor.js
         * @param {Object} event an event to emit
         * @param {String} event.type the type of event to emit
         * @param {Object} event.detail the custom event detail object
         */
        const emitEventFromMetaObject = ( event ) => {
            var id = event.detail.object.userData.metaObject.serial;
            if (id in serialToEntity) {
                serialToEntity[id].emit( event.type , event.detail );
            }
            else {
                createEntityForMetaObjectMesh(event.detail.object, function (entity) {
                    entity.emit( event.type , event.detail );
                });
            }
        }

        /**
         * Process the given extension object
         * @param {Object} object extension object to process
         */
        const processExtensionObject = ( object ) => {
            metaSerialToMetaObject.set(object.serial, object);

            /*
            if ( modelTreeSystem ) {
                modelTreeSystem.addEntry( object );
            }*/


            if ( object.hasOwnProperty('mesh_info') ){

                modelHasExtensionData = true;

                var threeJsMesh = createMesh( object.mesh_info.primitives, object.mesh_info.gltf_mesh_name, object );
                if (threeJsMesh !== null) {
                    threeJsMesh.userData.metaObject = object;

                    addMetaToMesh(threeJsMesh);
                    metaSerialToMesh.set(object.serial, threeJsMesh);
                }
            }
        }

        /**
         * Remove object from meta mesh. After removed object is not ray traceable anymore
         * @param threeJsMesh
         */
        const removeMetaFromMesh = ( threeJsMesh) => {
            const index =  metaObjectsMesh.indexOf(threeJsMesh);
            if (index > -1) {
                metaObjectsMesh.splice(index, 1);
            }
        }

        /**
         * Add object in meta mesh-
         * @param threeJsMesh
         */
        const addMetaToMesh = ( threeJsMesh) => {
            metaObjectsMesh.push(threeJsMesh);
        }


        /**
         * Find mesh with given name and material id, important function in animated scenes
         * @param meshName gltf mesh name read from the meta object
         * @param material name which is requested
         * @return ThreeJS mesh
         */
       
        export const findMesh = (meshName, materialName) => {
            let sanitizedName = THREE.PropertyBinding.sanitizeNodeName(meshName);
            if (sanitizedName in materialToMeshMap) {
                return materialToMeshMap[sanitizedName];
            }

            // 10000 max material per mesh, probably never going to hit that, but just in case to prevent forever loop
            for (let i = 0; i < 10000; i++) {
                // GLTF loader appends "_ + integer" for each material that is present in one mesh model
                let mesh = findFromMaterialMeshMap(`${sanitizedName}_${i}`, materialName);
                if (mesh !== null) {
                    return mesh;
                }
            }
            return null;
        }

        /**
         *
         * @param meshName
         *  @param materialName
         * @return {*}
         */
       const findFromMaterialMeshMap = (meshName, materialName) => {
             let foundMesh = null;
             if (meshName in materialToMeshMap) {
                 let mesh = materialToMeshMap[meshName];
                 if (Array.isArray(mesh.material)=== true) {
                     for (let j in mesh.material) {
                         if ( mesh.material[j].name === materialName ) {
                             foundMesh = mesh;
                         }
                     }
                 }
                 else if (mesh.material.name === materialName) {
                     foundMesh = mesh;
                 }
             }
             return foundMesh;
        }

        /**
         * Get the meta object serial of the given A-Frame entity
         * @param entity A-Frame entity that represents a meta object
         * @returns the serial id of the meta object that corresponds to the given entity
         */
        const getMetaObjectSerialForEntity = ( entity ) => {
            console.log("finalise this function!!")
            //return utils.getValueFromPath( entity, 'object3D.userData.metaMesh.userData.metaObject.serial');
        }

        /**
         * Create a mesh from the given array of primitives
         * @param {Array} primitives An array of primitives of different materials
         * @param {String} meshName The name of the created mesh
         * @param {Object} metadata Metadata related to the mesh
         */
        const createMesh = ( primitives, meshName, metadata ) => {
            var amountOfIndices = 0;

            for ( let i = 0; i < primitives.length; i++ ) {
                amountOfIndices += primitives[i].index_count;
            }

            var geometry = new THREE.BufferGeometry();
            var vertices = new Float32Array( amountOfIndices*3 );
            var buffer = new THREE.BufferAttribute( vertices, 3 );

            geometry.setAttribute( 'position', buffer );

            var primitive;
            var positionArray;
            var indices;
            amountOfIndices = 0;

            let mainPrimitiveMesh = null;
            let mainGeomInvTr = null;
            let primitiveGeomToMainGeomTr = null;

            // Find the "main" geometry node which is the parent
            // We choose the mesh with name meshName and material which first appears on the primitive list.
            for ( let i = 0; i < primitives.length; i++ ) {
                primitive = primitives[i];
                if (primitive.gltf_mesh_name && primitive.gltf_mesh_name === meshName)
                {
                    mainPrimitiveMesh = findMesh(meshName, primitive.material_name);
                    if (mainPrimitiveMesh !== null)
                    {
                        mainGeomInvTr = mainPrimitiveMesh.userData.originalMatrix.clone().invert();
                        primitiveGeomToMainGeomTr = new THREE.Matrix4();
                    }
                    break;
                }
                else if (primitive.gltf_mesh_name == null)
                {
                    mainPrimitiveMesh = findMesh(meshName, primitive.material_name);
                    break;
                }
            }

            // Copy the vertices from the specified mesh
            for ( let i = 0; i < primitives.length; i++ ) {
                primitive = primitives[i];

                let primitiveMeshName = meshName;
                let doTransformMetaMeshGeometry = false;
                // primitive.gltf_mesh_name is added in 28 CAD version.
                // Primitive can be part of other mesh than the "main", especially if explosion animations are present
                if (primitive.gltf_mesh_name)
                {
                    primitiveMeshName = primitive.gltf_mesh_name;
                    if (primitiveMeshName !== meshName && mainPrimitiveMesh != null)
                    {
                        // We need to transform the primitive mesh geometry to same frame as the meshName
                        doTransformMetaMeshGeometry = true;
                    }
                }

                let primitiveMesh = findMesh(primitiveMeshName, primitive.material_name);

                if (!primitiveMesh) {
                    continue;
                }

                if (doTransformMetaMeshGeometry)
                {
                    // Matrix to transform the primitiveMesh geometry to mainPrimitiveMesh frame
                    primitiveGeomToMainGeomTr.multiplyMatrices(mainGeomInvTr, primitiveMesh.userData.originalMatrix);
                }

                positionArray = primitiveMesh.geometry.attributes.position.array;
                indices = primitiveMesh.geometry.index.array;

                let position = new THREE.Vector3();
                for ( let j = 0; j < primitive.index_count; j++ ){
                    position.setX(positionArray[ indices[ j + primitive.index_offset ] * 3 ]);
                    position.setY(positionArray[ indices[ j + primitive.index_offset ] * 3 + 1 ]);
                    position.setZ(positionArray[ indices[ j + primitive.index_offset ] * 3 + 2]);

                    if (doTransformMetaMeshGeometry)
                    {
                        position.applyMatrix4(primitiveGeomToMainGeomTr);
                    }
                    // Copy the x,y and z coordinates corresponding to the index
                    vertices[amountOfIndices] = position.x;
                    vertices[amountOfIndices+1] = position.y;
                    vertices[amountOfIndices+2] = position.z;
                    amountOfIndices+=3;
                }
            }

            buffer.needsUpdate = true;

            if (mainPrimitiveMesh !== null) {
                geometry.computeVertexNormals();

                var mesh = new THREE.Mesh( geometry );
                mesh.material.visible = false;

                let quat = new THREE.Quaternion();
                mainPrimitiveMesh.getWorldQuaternion(quat);
                mesh.setRotationFromQuaternion(quat);
                mesh.updateMatrixWorld();

                mesh.userData.graphicsMesh = mainPrimitiveMesh;
                // This makes it so that the edge geometry is built on-demand.
                mesh.userData.edgesGeometryData = null;
                mesh.userData.geometry = geometry;
                mesh.userData.originalMatrix = mesh.matrixWorld.clone();
                Object.defineProperty(mesh.userData, "edgesGeometry", {
                    get : function () {
                        if (this.edgesGeometryData === null) {
                            this.edgesGeometryData = new THREE.EdgesGeometry(this.geometry);
                        }
                        return this.edgesGeometryData;
                    }
                });

                if ( !(metadata && "vxtype" in metadata && metadata.vxtype.group === "OPENINGS") ) {
                    doorlessMeshes.push(mesh);
                    //console.log("to be checked here!")
                }


                if ( metadata['animation_id'] !== undefined ) {
                    //kinematicMeshes.push(mesh);
                    console.log("to be checked here!")
                }

                return mesh;
            }
            else {
                return null;
            }

        }


        const newModelType = () => {
            return modelHasExtensionData;
        }


