import logo from './logo.svg';
import './App.css';
import { Viewer } from './components/viewer/Viewer';
import { Form } from './components/form/Form';
import { useState } from 'react';




function App() {

  

  const [focusValue, setFocusValue] = useState();
  const [formData, setFormData ] = useState();

  return (


    <div class="container">

      <div class="form-container">

        <Form updateFormData={setFormData}  setFocusValue={setFocusValue} />
      </div>

    <div className='viewer-container'>
    <Viewer focusValue={focusValue} formData={formData}  />

    </div>
      </div>

    
  );
}

export default App;
