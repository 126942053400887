import * as THREE from "three";
import * as BUI from "@thatopen/ui";
import * as OBC from "@thatopen/components";
import Stats from "stats.js";
import { useEffect, useRef } from "react";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

import TWEEN from 'three/addons/libs/tween.module.js';
import { initMesh } from "./createNewMeshes";
import { createOrFindEntityForAllMetaObjectMeshes, findMesh, init_exention } from "./custom-gltf-extension";
import { lights } from "./lights";
import { creatNewGroupArray, setMaterialsIntoArray } from "./materialHandler";


let metaSerialToMesh = null;
let metaObjectsMesh = null;
let materialToMeshMap = null;
let metaSerialToMetaObject = null;
let material_list = [];

export const Viewer = ({focusValue='default', formData}) => {

  console.log(formData);


  const containerRef = useRef(null);
  const loader = new GLTFLoader();


  const animate = () => {

    requestAnimationFrame( animate );

    TWEEN.update();

    if(window.controls){
      window.controls.update();
    }
    

  }

  

console.log()
const views = {
  default:{
    "x": 7.700849313723983,
    "y": 12.134587497080235,
    "z": -7.447748301629784,
    "qx": -0.12241315316548872,
    "qy": 0.8092952546257337,
    "qz": 0.5449880121951101,
    "qw": 0.1817808497503902,
    "lx": 5.675871514278636,
    "ly": 0.46566119648160403,
    "lz": -3.167530664408128
},
  chalet: {


    
      x: 9.177193562109563,
      y: 13.080687188806149,
      z: -1.1586552341305623,
  
      qx:        -0.7027422572464893,
       qy: -0.005153651534546253,
        qz: -0.005090876825274912,
        qw: 0.7114076487704637,

        lx: 9.179296099429317,
        ly: 1.2393446354130035,
        lz: -1.3037642528205389
},
door:{
  "x": 14.47545123593054,
  "y": 2.505534011744965,
  "z": -2.51010502777511,
  "qx": -0.09158935456714615,
  "qy": 0.864428976044283,
  "qz": 0.17064723125459497,
  "qw": 0.46395415503058784,
  "lx": 7.968251621850255,
  "ly": -0.7023580793304516,
  "lz": 1.8056652209678803
},
gevel: {
    "x": 15.568385324775758,
    "y": 2.435971042644452,
    "z": 3.739894867160606,
    "qx": -0.09325463023784628,
    "qy": 0.28502645039812186,
    "qz": 0.027874378529942928,
    "qw": 0.9535651606126447,
    "lx": 4.908930788267215,
    "ly": -1.3998576013554147,
    "lz": -12.497791162504408
},

keuken:{
  "x": 4.07750804357546,
  "y": 1.9706644517370377,
  "z": -4.022792833177379,
  "qx": -0.00009460057773178081,
  "qy": 0.9925433765380661,
  "qz": 0.12188947178520979,
  "qw": 0.0007703304926106577,
  "lx": 4.0711597376257185,
  "ly": 0.9507899105716648,
  "lz": 0.06698764912911927
},
badkamer: {
  "x": 9.495521340008434,
  "y": 3.6461902920862643,
  "z": -3.09301169602426,
  "qx": -0.39700349777020477,
  "qy": -0.4955199523708824,
  "qz": -0.27206721714030224,
  "qw": 0.7230682048867265,
  "lx": 9.7775929222057,
  "ly": 3.1707324934938397,
  "lz": -3.2021606016168795
},
pannen:{
  "x": -2.0388047972511583,
  "y": 7.088912129431667,
  "z": 5.218952377804248,
  "qx": -0.23960113692245374,
  "qy": -0.5093793517827999,
  "qz": -0.15016521713037534,
  "qw": 0.8127572692550891,
  "lx": 14.04456596361595,
  "ly": -4.450814130878947,
  "lz": -2.5722624526243787
}


};

const animation_duration = 500;


// show objects like fridge
useEffect(() => {
  if(formData){
    console.log(formData)
    set_visible_object_with_children(1357209672502, formData.cooker)
    set_visible_object_with_children(1365799607092, formData.sink)
    set_visible_object_with_children(1370094574387, formData.frigo)

    // Update cladding:
    if(material_list && metaSerialToMetaObject){
      console.log("update cladding!!")
      metaSerialToMetaObject.forEach((value, key) => {

        if (value.type == "WALLS/SIDING") {  
          //const material_test = new THREE.MeshStandardMaterial().copy(material)
          console.log(value)
          //['1', '2', '3', '4', '15', '16', '17', '18', '21', '25', '27', '33', '36', '41', '43', '45', '47', '48', '49', '53', '55', '61', '62', '63', '66', '114']
          const material_name = formData.cladding.materialName;
          const new_material = material_list.find(ml => ml.name == material_name).clone()
          console.log(new_material)
          //new_material.color = new THREE.Color( 0Xffffff );
          //new_material.emissive= new THREE.Color(0Xffffff);
          //new_material.emissiveIntensity = 14;
          change_material_with_children(value.serial, new_material)  
        }


        // Update pannen:
        if (value.name == "RL-TILE-40") {  
          //const material_test = new THREE.MeshStandardMaterial().copy(material)
          console.log(value.serial)
          //['1', '2', '3', '4', '15', '16', '17', '18', '21', '25', '27', '33', '36', '41', '43', '45', '47', '48', '49', '53', '55', '61', '62', '63', '66', '114']
          const material_name = formData.pannen.materialName;
          const new_material = material_list.find(ml => ml.name == material_name).clone()
        // new_material.color = new THREE.Color( 0xBD765F );
        // new_material.emissive = new THREE.Color( 0xff0000 );
        // new_material.emissiveIntensity = 0.5;
          //new_material.wireframe  = true;
          //const new_material1 = material_list.find(ml => ml.name == '3').clone()

          change_material_with_children(value.serial, new_material)  
        }




      });
    }


  
}

},[formData]);

useEffect(() => {
  move_to(focusValue);
},[focusValue]);



const move_to = (focus_value) => {

  if(window.camera && focusValue){

    switch(focus_value){
      case 'chalet':
  
      set_visible_object_with_children(2083059145571, false)
      set_visible_object_with_children(2078764178281, false)
      break;
  
      case 'gevel':
  
      set_visible_object_with_children(2083059145571, true)
      set_visible_object_with_children(2078764178281, true)
      
      break;

      case 'pannen':
  
      set_visible_object_with_children(2083059145571, true)
      set_visible_object_with_children(2078764178281, true)
      
      break;
  
  
      default:
        set_visible_object_with_children(2083059145571, false)
        set_visible_object_with_children(2078764178281, false)
  
    }
  


  TWEEN.removeAll();
  new TWEEN.Tween( window.camera.position )
          .to(views[focus_value], animation_duration )
          .easing( TWEEN.Easing.Cubic.InOut )
          .start();

        const a = new THREE.Quaternion(views[focus_value]['qx'],views[focus_value].qy,views[focus_value].qz,views[focus_value].qw);
      console.log(a)
      new TWEEN.Tween( window.camera.quaternion )
      .to( a, animation_duration )
      .easing( TWEEN.Easing.Cubic.InOut )
      .start();


      const b = new THREE.Vector3(views[focus_value].lx,views[focus_value].ly,views[focus_value].lz);

      new TWEEN.Tween( window.controls.target )
      .to( b, animation_duration )
      .easing( TWEEN.Easing.Cubic.InOut )
      .start();


          new TWEEN.Tween( this )
          .to( {}, animation_duration )
          .onUpdate( window.renderer.render(window.scene, window.camera) )
          .start();

  }

}



const onWindowResize = () => {
  if(window){
  console.log("test window resize")
  const newWidth = window.innerWidth;
  const newHeight = window.innerHeight;

  window.renderer.setSize(newWidth ,newHeight)

  window.camera.aspect = newWidth / newHeight
  window.camera.updateProjectionMatrix();
}

}

window.addEventListener("resize", onWindowResize, false)



const change_material_with_children = (serial, new_material) => {
  if(metaSerialToMetaObject){
    console.log("hier")
    change_material(serial, new_material);
  const meta = metaSerialToMetaObject.get(serial);
  if (meta.hasOwnProperty('children')){
    meta.children.forEach(child_serial => {
      change_material_with_children(child_serial, new_material);
    });
  } }

}

const change_material = (serial, new_material) => {
  if(metaSerialToMesh){
    const selection_mesh = metaSerialToMesh.get(serial);
    if (selection_mesh){
    selection_mesh.userData.metaObject.mesh_info.primitives.forEach(p => { 
      const mesh = findMesh(p.gltf_mesh_name,p.material_name);
      if(mesh){
        console.log(mesh.material)
        const n = mesh.material.name;
      mesh.material = new_material;
      mesh.material.name = n;
      //mesh.material = new_material
      mesh.needsUpdate = true;
      mesh.visible = true;
    }
    });


}
  }}


const set_visible_object = (serial, visible) => {    
  if(metaSerialToMesh){
  const selection_mesh = metaSerialToMesh.get(serial);
  if (selection_mesh){
  selection_mesh.userData.metaObject.mesh_info.primitives.forEach(p => { 
    const m = findMesh(p.gltf_mesh_name,p.material_name)
    if(m){
    m.visible = visible;
    }
  });
}
}
}

const set_visible_object_with_children = (serial, visible) => {
  if(metaSerialToMetaObject){
  set_visible_object(serial, visible);
  const meta = metaSerialToMetaObject.get(serial);
  if (meta.hasOwnProperty('children')){
    meta.children.forEach(child_serial => {
      set_visible_object_with_children(child_serial, visible);
    });
  } }
}


useEffect(() => {
  if (typeof window !== 'undefined') {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true  });
    //renderer.gammaOutput = true;
    renderer.physicallyCorrectLights = true;

    renderer.toneMappingExposure =0.145; // to allow for very bright scenes.
		renderer.shadowMap.enabled = true;


    const canvas_width = (window.innerWidth / 4) * 3;
    const canvas_height = window.innerHeight;
    //renderer.setSize(, canvas_height);

    renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    
    //renderer.physicallyCorrectLights = true;
    //renderer.shadowMap.enabled = true
    //renderer.shadowMap.type = THREE.PCFSoftShadowMap;



    

    containerRef.current?.appendChild(renderer.domElement);

    // Add this inside the useEffect hook after initializing the renderer
    if (typeof window !== 'undefined') {
      const geometry = new THREE.BoxGeometry();
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

      // Load a glTF resource
      loader.load(
        // resource URL
        'model2beds.glb',
        // called when the resource is loaded

        (model) => {




          //const floorMaterial = new THREE.MeshPhongMaterial( { color: '#ffffff' } );
          //const floor = new THREE.Mesh( new THREE.PlaneGeometry(50, 50, 8,2 ), floorMaterial );
          //floor.rotateX(Math.PI / -2);
          //floor.position.set(0,-0.1,0);
          //scene.add(floor);

          scene.add(model.scene);
          lights(scene);

          model.scene.visible = true;
          
          scene.matrixAutoUpdate = false;
          const extensionData = model.userData.gltfExtensions.VERTEXSYSTEMS_meta;
          console.log(extensionData);

          if ( extensionData !== null ) {
            console.log('ok')
          }

          const init_ext = init_exention(scene, extensionData);
          metaSerialToMesh =init_ext.metaSerialToMesh;
          metaObjectsMesh =init_ext.metaObjectsMesh;
          materialToMeshMap =init_ext.materialToMeshMap;
          metaSerialToMetaObject =init_ext.metaSerialToMetaObject;

          //let camera = gltf.cameras[10];
          var camera = new THREE.PerspectiveCamera(60, 2, 0.1, 100);
          camera.position.set(10, 6, -16);
          camera.lookAt(new THREE.Vector3(-20,-20,-20))          

          const controls = new OrbitControls(camera, renderer.domElement);
          controls.listenToKeyEvents(window); // optional

          controls.screenSpacePanning = true;
          //controls.enableDamping = true;

          // Add this function inside the useEffect hook
          const renderScene = () => {
            renderer.render(scene, camera);
            requestAnimationFrame(renderScene);
            
          };


  
          scene.traverse( (object) => {
            if(object.isMesh){
              //object.material.opacity = 0.10;
              //object.material.transparent = true;
              object.visible = true;
              object.castShadow = true;
              object.receiveShadow = true;

            }
          });



          setInterval(() => {
            const view = {}
            view.x = camera.position.x;
            view.y = camera.position.y;
            view.z = camera.position.z;
            view.qx = camera.quaternion.x
            view.qy =camera.quaternion.y
            view.qz =camera.quaternion.z
            view.qw =camera.quaternion.w
            view.lx =controls.target.x
            view.ly =controls.target.y
            view.lz =controls.target.z
            console.log(view)
            
            
          }, 5000)
          



          const show_object = (serial) => {

 
            
            // selection mesh
            
            const selection_mesh = metaSerialToMesh.get(serial);
            //scene.add(selection_mesh)
            console.log(selection_mesh)
            //const material = new THREE.MeshBasicMaterial( { color: new THREE.Color(0xff0000) } );
            //selection_mesh.material.visible = false;
            //selection_mesh.material.opacity = 1;
            //selection_mesh.material.transparent = true;
            //selection_mesh.material = material;
 
            
            // get names
            
            console.log(selection_mesh);
            selection_mesh.userData.metaObject.mesh_info.primitives.forEach(p => { 
              const m = findMesh(p.gltf_mesh_name,p.material_name)
              m.visible = true;
              //m.material.opacity = 1;
              console.log(m);
            });
            


          }

          /*
          let i = 1;
          metaSerialToMesh.forEach((value,key) => {
            console.log(value);
            i++;
            setInterval(() => {show_object(key)}, 1000 * i)
            

          } )
            */
            



          



          /*
          const serials_to_hide = [ 2615635090605];
          serials_to_hide.forEach(serial => {
            // selection mesh
            
            const selection_mesh = metaSerialToMesh.get(serial);
            //const material = new THREE.MeshBasicMaterial( { color: new THREE.Color(0xff0000) } );
            //selection_mesh.material.visible = true;
 
            // get names
            selection_mesh.userData.metaObject.mesh_info.primitives.forEach(p => { 
              const m = findMesh(p.gltf_mesh_name,p.material_name)
              m.visible = false
              console.log(m);
            });



          })
*/







          
        

/*

          let meta = el.object3D.userData.metaMesh;
          let primitiveArray = meta.userData.metaObject.mesh_info.primitives;
          let meshName = meta.userData.metaObject.mesh_info.gltf_mesh_name;
          primitiveArray.forEach(function (primitive) {
              let primitiveMeshName = primitive.gltf_mesh_name ? primitive.gltf_mesh_name : meshName;
              let graphicsMesh = self.scene.systems['custom-gltf-extensions'].findMesh(primitiveMeshName,primitive.material_name);
              if (graphicsMesh !== undefined && graphicsMesh != null) {


*/





          /*
          bdmeshes.map((x, i) => {
            //if (x.isMesh) x.material.wireframe = true;
            x.material.visible = true;
            scene.add(x);
        });*/


          window.renderer = renderer;
          window.scene = scene;
          window.controls = controls;
          window.camera = camera;
          

          onWindowResize();
          renderScene();
          animate();
          move_to('gevel');


          scene.updateMatrixWorld(true);





          scene.traverse( (object) => {
            if(object.isMesh){
              object.visible = true;
            }
          });



          const timeout = (delay) => {
            return new Promise( res => setTimeout(res, delay) );
          }



          // Pannen = 63
          // Battens = 4 (name)
          // MDF = 3


          model.parser.getDependencies( 'material' ).then(  ( materials ) => {
            material_list = materials;
            

            metaSerialToMetaObject.forEach((value, key) => {




              


              if (value.vxtype.main_type== 'EAVETRIM') {  
                //const new_material = new THREE.MeshBasicMaterial( { color: new THREE.Color(0x151515) } );
                const new_material = material_list.find(ml => ml.name == '3').clone()

                change_material_with_children(value.serial, new_material)  
              }

              if (value.vxtype.main_type== 'WINDOW_EXT' || value.vxtype.main_type== 'DOOR_EXT') {  
                //const new_material = new THREE.MeshBasicMaterial( { color: new THREE.Color(0x151515) } );
                const new_material = material_list.find(ml => ml.name == '3').clone()
                //value.serial

               
                      const selection_mesh = metaSerialToMesh.get(value.serial);

                      if (selection_mesh){
                      selection_mesh.userData.metaObject.mesh_info.primitives.forEach(p => { 
                        const mesh = findMesh(p.gltf_mesh_name,p.material_name);
                        if(mesh){
                          if(mesh.material.transparent != true){
                            mesh.material = new_material;
                            mesh.visible = true;
                          }  
                      }
                      });
                    };
              }

            });


            //process(materials);


            console.log(material_list.filter(ml => ml.map && ml.map.userData && ml.map.userData.mimeType).map(x => x.name));

          });


/*
            console.log(index_key)

            await timeout(100); //for 1 sec delay

              const new_material = materialToMeshMap[index_key].material.clone();

            metaSerialToMetaObject.forEach((value, key) => {
               if (value.type == "WALLS/SIDING") {  
                  //const material = new THREE.MeshBasicMaterial( { color: new THREE.Color(0xff0000) } );
                  change_material_with_children(value.serial, new_material)  
               }
            });
  
*/
          
        





          /*
          const texture = new THREE.TextureLoader().load('/wood.png' ); 
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

          // immediately use the texture for material creation 
          const material = new THREE.MeshBasicMaterial( { color: new THREE.Color(0xff0000) } );
          material.wireframe = true;
          material.side = THREE.BackSide

          
          let meshes = initMesh(scene, structured_data);
          console.log(meshes)
          console.log(meshes[40])
          scene.add(meshes[40].data);
          //meshes[40].data.material = material;
          //scene.add(meshes[40].data.userData.graphicsMesh);

          scene.updateMatrixWorld(true);


          scene.updateMatrixWorld(true);
          scene.traverse( (object) => {
              if (object.isMesh) {
                  object.geometry.computeVertexNormals();
                  object.matrixAutoUpdate = false;
                  // The original matrix is copied and stored so that it can be used in gradual loading without it
                  // changing mid-loading. Without this, the initial orientation of the metaObjects of animated
                  // objects is non-deterministic!
                  object.userData.originalMatrix = object.matrixWorld.clone();
              }
          });




/*
          // Calculate the normals after the meshes have been loaded
          


          const texture = new THREE.TextureLoader().load('/wood.png' ); 
          // immediately use the texture for material creation 
          const material = new THREE.MeshBasicMaterial( { map:texture } );

          const geometry = new THREE.BoxGeometry( 10, 10, 10 );
          const mesh = new THREE.Mesh( geometry, material.clone() );
          scene.add( mesh );

          meshes
          //.filter((x) => x.hasOwnProperty('vxtype') && x['vxtype']['main_type'] == 'WINDOW_EXT')
          //.filter((x) => x.hasOwnProperty('vxtype') && x['vxtype']['main_type'] == 'SIDING')
          //.filter((x) => x.parent == '1129576405719')
          //.filter((x) => x.hasOwnProperty('type') && x['type'] == 'profile')
          .map((x, i) => {
            //x.data.material = material.clone();
            //if (x.data.isMesh) x.data.material.wireframe = true;
            setTimeout(() => scene.add(x.data), 10 * i);
          });

          */


          /*

        let i = 0;
        const all_meshes = []
          scene.traverse((mesh) => {
            i++
            if (mesh.isMesh) {
              setTimeout(() => { mesh.visible = false; console.log(i)}, 500 * i);
              all_meshes.push(mesh);
             
              
            }
          })
            console.log(all_meshes);
            */
     
          

            /*

            meshes
            //.filter((x) => x.hasOwnProperty('vxtype') && x['vxtype']['main_type'] == 'WINDOW_EXT')
            //.filter((x) => x.hasOwnProperty('vxtype') && x['vxtype']['main_type'] == 'SIDING')
            //.filter((x) => x.parent == '1129576405719')
            //.filter((x) => x.hasOwnProperty('type') && x['type'] == 'profile')
            .map((x, i) => {
              x.data.material = material.clone();
              //if (x.data.isMesh) x.data.material.wireframe = true;
              setTimeout(() => scene.add(x.data), 10 * i);
            });
               
*/


  
          
    



          // calculate the tree: as an example we show item 5 from the tree.
          //const tree = get_tree(structured_data.objects);
          //console.log(tree);
          //setTimeout(() => highlight_item_from_tree(tree[1], scene, meshes), 3000);
          /*

          const geometry = new THREE.BufferGeometry();

          const positions = [
            0,
            0,
            0, // v1
            0,
            10,
            0, // v2
            0,
            10,
            10, // v3
          ];

          geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
          geometry.computeVertexNormals();

          const object = new THREE.Mesh(geometry, new THREE.MeshNormalMaterial());
          scene.add(object);
          /*const o = scene.getObjectById(47);
          if (o && o.isMesh) {
            o.visible = true;
          }
          */
        },
        () => {},
        (error) => {
          console.log(error);
        }
      );
    }
  }
}, [1]);


/*
  constuseEffect(() => {
    const container = document.getElementById("container");
    const components = new OBC.Components();
    const worlds = components.get(OBC.Worlds);


    const loader = new GLTFLoader();

    const world = worlds.create();

    world.scene = new OBC.SimpleScene(components);
    world.renderer = new OBC.SimpleRenderer(components, container);
    world.camera = new OBC.SimpleCamera(components);

    components.init();

    // Load IFC via fragments
    const fragments = components.get(OBC.FragmentsManager);
    const fragmentIfcLoader = components.get(OBC.IfcLoader);


    
    (async () => {
      await fragmentIfcLoader.setup();
      fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
      fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true;

      const file = await fetch("/Chalet_01.ifc");
      const data = await file.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await fragmentIfcLoader.load(buffer);
      model.name = "example";
      //world.scene.three.add(model);
      // for (const mesh of model.children) {
      //   culler.add(mesh as any);
      // }

      const material = new THREE.MeshLambertMaterial({ color: "#6528D7" });
      const geometry = new THREE.BoxGeometry();
      const cube = new THREE.Mesh(geometry, material);
      //world.scene.three.add(cube);




      world.scene.setup();

      // Load a glTF resource
      loader.load(
        // resource URL
        'model.glb',
        // called when the resource is loaded

        (gltf) => {
          console.log('loaded!');
          console.log(gltf);


          const model = gltf.scene;
          model.visible = true;
          world.scene.three.add(model);

        },
        () => {},
        (error) => {
          console.log(error);
        }
      );

      
      setInterval(() => {
        console.log("position");
        console.log(world.camera.controls.getPosition());
        console.log(world.camera.controls.getTarget());
      }, 2000);



        



      //world.camera.controls.setLookAt(10, 10, 10, 0, 0, 0);

      world.scene.three.background = null;

      window.camera = world.camera;
    })();
  }, []);

  */

  return <div class="full-screen" ref={containerRef}></div>;
};
